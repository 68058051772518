import React, { useState } from "react";
import { usePostUserSurveyMutation, useGetSurveyTemplateQuery } from "../../app/api/userApiSlice";
import useAuth from "../../hooks/useAuth";
import { ReactComponent as Logo } from "../../assets/Logo.svg";

const SurveyRow = ({ question, type, onChange }) => {
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState(type === "range" ? "3" : "");

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);

    if (type === "yesNo") {
      setShowInput(newValue === "no");
    }
  };

  return (
    <div className='space-y-4'>
      <p className='text-[17px] font-medium text-[#171D30] leading-tight'>{question}</p>
      {type === "yesNo" && (
        <div className='space-y-4'>
          <div className='flex gap-8'>
            <label className='flex items-center cursor-pointer group'>
              <input
                type='radio'
                name={question}
                value='yes'
                onChange={handleChange}
                className='w-5 h-5 mr-3 accent-[#7A17F9] cursor-pointer'
              />
              <span className='text-[15px] text-[#42485D] group-hover:text-[#171D30] transition-colors duration-200'>
                Yes
              </span>
            </label>
            <label className='flex items-center cursor-pointer group'>
              <input
                type='radio'
                name={question}
                value='no'
                onChange={handleChange}
                className='w-5 h-5 mr-3 accent-[#7A17F9] cursor-pointer'
              />
              <span className='text-[15px] text-[#42485D] group-hover:text-[#171D30] transition-colors duration-200'>
                No
              </span>
            </label>
          </div>
          {showInput && (
            <textarea
              placeholder='Please explain why...'
              className='w-full p-4 border border-[#E9E9F0] rounded-xl min-h-[80px] resize-y text-[15px] text-[#171D30] placeholder-[#42485D]/40 focus:outline-none focus:ring-2 focus:ring-[#7A17F9]/10 focus:border-[#7A17F9]/40 transition-all duration-200'
              onChange={(e) => onChange(`no - ${e.target.value}`)}
            />
          )}
        </div>
      )}
      {type === "text" && (
        <textarea
          className='w-full p-4 border border-[#E9E9F0] rounded-xl min-h-[80px] resize-y text-[15px] text-[#171D30] placeholder-[#42485D]/40 focus:outline-none focus:ring-2 focus:ring-[#7A17F9]/10 focus:border-[#7A17F9]/40 transition-all duration-200'
          onChange={handleChange}
        />
      )}
      {type === "range" && (
        <div className='flex items-center gap-4'>
          <input
            type='range'
            min='1'
            max='5'
            value={value}
            className='w-full h-2 appearance-none bg-gray-200 rounded-full 
              [&::-webkit-slider-thumb]:appearance-none 
              [&::-webkit-slider-thumb]:h-4 
              [&::-webkit-slider-thumb]:w-4 
              [&::-webkit-slider-thumb]:rounded-full 
              [&::-webkit-slider-thumb]:bg-[#7A17F9] 
              [&::-webkit-slider-thumb]:cursor-pointer
              [&::-webkit-slider-thumb]:border-2
              [&::-webkit-slider-thumb]:border-white
              [&::-webkit-slider-thumb]:shadow-lg
              hover:[&::-webkit-slider-thumb]:bg-[#6845BF]
              focus:[&::-webkit-slider-thumb]:ring-4
              focus:[&::-webkit-slider-thumb]:ring-[#7A17F9]/20
              active:[&::-webkit-slider-thumb]:scale-110
              transition-all duration-150'
            onChange={handleChange}
          />
          <span className='text-[15px] font-medium w-[40px] text-[#42485D] tabular-nums'>{value}/5</span>
        </div>
      )}
    </div>
  );
};

const UserSurvey = ({ onClose, templateId }) => {
  const { id: userId } = useAuth();
  const [postUserSurvey] = usePostUserSurveyMutation();
  const [responses, setResponses] = useState({});
  const { data: surveyTemplate } = useGetSurveyTemplateQuery(templateId);
  const questions = surveyTemplate?.questions || [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postUserSurvey({
        userId,

        surveyResponses: responses,
      });
      onClose();
    } catch (err) {
      console.error("Failed to submit survey:", err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='w-[640px] bg-white rounded-3xl p-12 relative shadow-2xl'>
      <div className='flex items-center gap-4 mb-12'>
        <Logo className='w-12 h-12 text-[#7A17F9]' />
        <h2 className='text-[28px] font-semibold text-[#171D30] tracking-tight'>Help us improve FocalStudio</h2>
      </div>

      <div className='space-y-10'>
        {questions.map((q) => (
          <SurveyRow
            key={q.id}
            question={q.question}
            type={q.type}
            onChange={(value) => setResponses((prev) => ({ ...prev, [q.question]: value }))}
          />
        ))}
      </div>

      <div className='flex justify-end mt-12 gap-3'>
        <button
          type='button'
          onClick={onClose}
          className='px-8 py-3 rounded-xl text-[15px] font-medium text-[#42485D] hover:bg-gray-50 transition-colors duration-200'>
          Cancel
        </button>
        <button
          type='submit'
          className='px-8 py-3 rounded-xl bg-[#7A17F9] text-[15px] font-medium text-white hover:bg-[#6845BF] transition-all duration-200 hover:shadow-lg hover:shadow-[#7A17F9]/20'>
          Submit Feedback
        </button>
      </div>

      <button
        type='button'
        onClick={onClose}
        className='absolute top-8 right-8 text-[#42485D] hover:text-[#171D30] transition-colors duration-200'>
        <svg
          width='20'
          height='20'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'>
          <path d='M18 6L6 18M6 6L18 18' />
        </svg>
      </button>
    </form>
  );
};

export default UserSurvey;
