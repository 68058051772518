import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (userData) => ({
        url: "/api/v1/users/",
        method: "POST",
        body: userData,
      }),
    }),
    getUser: builder.query({
      query: (userId) => `/api/v1/users/${userId}`,
    }),
    addWebsiteId: builder.mutation({
      query: ({ userId, websiteId }) => ({
        url: `/api/v1/users/${userId}/authorized-websites`,
        method: "POST",
        body: { website_id: websiteId },
      }),
    }),
    removeWebsiteId: builder.mutation({
      query: ({ userId, websiteId }) => ({
        url: `/api/v1/users/${userId}/authorized-websites`,
        method: "DELETE",
        body: { website_id: websiteId },
      }),
    }),
    postUserSurvey: builder.mutation({
      query: ({ userId, surveyResponses, templateId }) => ({
        url: `/api/v1/users/${userId}/survey`,
        method: "POST",
        body: {
          survey_responses: [
            {
              template_id: "survey-template-Ko0jHf4r8A",
              responses: surveyResponses,
              submitted_at: new Date().toISOString(),
            },
          ],
        },
      }),
      invalidatesTags: (result, error, { userId }) => [{ type: "Survey", id: userId }],
    }),
    getShouldShowSurvey: builder.query({
      query: ({ userId, templateId }) => `/api/v1/users/${userId}/surveys/available`,
      providesTags: (result, error, { userId }) => [{ type: "Survey", id: userId }],
    }),
    markSurveyShown: builder.mutation({
      query: ({ userId, templateId }) => ({
        url: `/api/v1/users/${userId}/mark-survey-shown/${templateId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { userId }) => [{ type: "Survey", id: userId }],
    }),
    getSurveyTemplate: builder.query({
      query: (templateId) => `/api/v1/survey-templates/${templateId}`,
    }),
  }),
});

export const {
  useCreateUserMutation,
  useGetUserQuery,
  useAddWebsiteIdMutation,
  useRemoveWebsiteIdMutation,
  usePostUserSurveyMutation,
  useGetShouldShowSurveyQuery,
  useMarkSurveyShownMutation,
  useGetSurveyTemplateQuery,
} = userApiSlice;
