import React, { useEffect, useRef, useState, useMemo } from "react";
import { agent_properties } from "../../../constants/agentConstants";
import { ReactComponent as AIMagicIcon } from "../../../assets/icons/ai/ai-magic-line.svg";
import { Expander } from "../../../components/layout/Expander";
import FixesListCard from "./FixesListCard";

//! Fix List Filters / Header

const FixesListHeader = ({
  fixes,
  filter,
  setFilter,
  selectedCategory,
  setSelectedCategory,
  searchTerm,
  setSearchTerm,
}) => {
  const FILTER_OPTIONS = [
    { id: "all", label: "All" },
    { id: "ai", label: "AI Solutions" },
    { id: "review", label: "Needs review" },
  ];

  const counts = {
    all: fixes?.length || 0,
    ai: fixes?.filter((fix) => !fix.needs_review).length || 0,
    review: fixes?.filter((fix) => fix.needs_review).length || 0,
  };

  const hasTags = fixes?.some((fix) => fix.tags?.length > 0);

  const uniqueTags = useMemo(() => {
    if (!fixes) return new Set();
    return new Set(fixes.flatMap((fix) => fix.tags || []));
  }, [fixes]);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  return (
    <div className='sticky top-0 z-10'>
      <div className='flex justify-between items-center mb-4'>
        <div className='flex justify-between w-5/9 min-w-[450px] pr-4 space-x-1' style={{ maxWidth: "300px" }}>
          {FILTER_OPTIONS.map((option) => (
            <FilterButton
              key={option.id}
              option={option}
              count={counts[option.id]}
              isSelected={filter === option.id}
              onClick={() => setFilter(option.id)}
            />
          ))}
        </div>
      </div>

      {hasTags ? (
        <div className='relative'>
          <input
            type='text'
            placeholder='Search by tags...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsSearchFocused(true)}
            onBlur={() => setTimeout(() => setIsSearchFocused(false), 200)}
            className='w-full px-4 py-2 mb-4 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500'
          />
          {isSearchFocused &&
            (searchTerm === "" ||
              Array.from(uniqueTags).some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))) && (
              <div className='absolute z-20 w-full mt-[-1rem] bg-white border-x border-b border-gray-300 rounded-b-xl shadow-lg'>
                {Array.from(uniqueTags)
                  .filter((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map((tag) => (
                    <div
                      key={tag}
                      className='px-6 py-3 hover:bg-gray-50 cursor-pointer text-gray-700 text-[15px]'
                      onClick={() => {
                        setSearchTerm(tag);
                        setIsSearchFocused(false);
                      }}>
                      {tag}
                    </div>
                  ))}
              </div>
            )}
        </div>
      ) : (
        <Expander
          options={Object.entries(agent_properties).map(([key, value]) => ({
            value: key,
            label: value.name,
          }))}
          value={selectedCategory}
          onChange={setSelectedCategory}
        />
      )}
    </div>
  );
};

const FilterButton = ({ option, count, isSelected, onClick }) => (
  <div
    className={`flex items-center cursor-pointer p-2 gap-2 ${
      isSelected ? "bg-white border-2 border-gray-200 rounded-xl shadow-md" : ""
    }`}
    onClick={onClick}>
    {option.id === "ai" && (
      <span className='bg-ai-purple p-1 rounded-md'>
        <AIMagicIcon className='w-4 h-4 text-white' />
      </span>
    )}
    <span className={`text-sm font-medium ${isSelected ? "" : "text-gray-500"}`}>{option.label}</span>
    <span className='bg-gray-200 text-gray-500 px-2.5 py-0.5 rounded-md text-sm font-medium'>{count}</span>
  </div>
);

//! Fix List

function FixesList({ fixes, selectedFix, onFixClick, onFixesFiltered }) {
  const [filter, setFilter] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const selectedItemRef = useRef(null);
  const scrollContainerRef = useRef(null);

  // Add this new ref to track the website
  const websiteRef = useRef(null);

  // Initial sort only happens once when fixes array changes
  const initialSortedFixes = useMemo(() => {
    if (!fixes) return [];
    const statusOrder = { to_do: 0, in_progress: 1, done: 2, discarded: 3 };
    const severityOrder = { high: 0, medium: 1, low: 2 };

    return [...fixes].sort((a, b) => {
      // First by status
      if (a.status !== b.status) {
        return statusOrder[a.status] - statusOrder[b.status];
      }
      // Then by severity (high to low)
      if (a.severity !== b.severity) {
        return severityOrder[a.severity] - severityOrder[b.severity];
      }
      // Finally by shortlink
      return a.shortlink_url.localeCompare(b.shortlink_url);
    });
  }, [fixes]);

  // Store the initial order of fix IDs
  const fixOrder = useRef(initialSortedFixes.map((fix) => fix.id));

  const filteredFixes = useMemo(() => {
    if (!fixes) return [];

    const fixesMap = fixes.reduce((acc, fix) => {
      acc[fix.id] = fix;
      return acc;
    }, {});

    const orderedFixes = fixOrder.current.map((id) => fixesMap[id]).filter(Boolean);

    return orderedFixes.filter((fix) => {
      const statusMatch = filter === "all" ? true : filter === "ai" ? !fix.needs_review : fix.needs_review;
      const categoryMatch = selectedCategory === "all" ? true : fix.ai_agent === selectedCategory;
      const searchMatch =
        !searchTerm.trim() ||
        (fix.tags && fix.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())));
      return statusMatch && categoryMatch && searchMatch;
    });
  }, [fixes, filter, selectedCategory, searchTerm]);

  useEffect(() => {
    onFixesFiltered?.(filteredFixes);
  }, [filteredFixes, onFixesFiltered]);

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({ behavior: "smooth", top: "start" });
    }
  }, [selectedFix]);

  // Modify the reset effect
  useEffect(() => {
    // Only reset if website changed
    const firstFix = fixes?.[0];
    const currentWebsite = firstFix?.website_id;

    if (currentWebsite !== websiteRef.current) {
      websiteRef.current = currentWebsite;
      setFilter("all");
      setSelectedCategory("all");
      fixOrder.current = initialSortedFixes.map((fix) => fix.id);
    }
  }, [fixes]); // Remove initialSortedFixes dependency

  return (
    <div className='flex flex-col h-full'>
      <div className='z-10 pr-4'>
        <FixesListHeader
          fixes={fixes}
          filter={filter}
          setFilter={setFilter}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>

      <div
        ref={scrollContainerRef}
        className='overflow-y-auto mt-4 pr-4'
        style={{
          overscrollBehavior: "contain",
          height: "calc(100% - 60px)",
          position: "relative",
        }}>
        {filteredFixes.map((fix) => (
          <FixesListCard
            key={fix.id}
            fix={fix}
            isSelected={selectedFix?.id === fix.id}
            onClick={() => onFixClick(fix)}
            elementRef={selectedFix?.id === fix.id ? selectedItemRef : null}
          />
        ))}
        <div className='h-40' />
      </div>
    </div>
  );
}

export default FixesList;
