import { ReactComponent as ContentIcon } from "../assets/icons/agents/content.svg";
import { ReactComponent as UxIcon } from "../assets/icons/agents/ux.svg";
import { ReactComponent as AccessibilityIcon } from "../assets/icons/agents/accessibility.svg";
import { ReactComponent as SeoIcon } from "../assets/icons/agents/seo.svg";
import { ReactComponent as PerformanceIcon } from "../assets/icons/agents/performance.svg";
import { ReactComponent as ReviewsIcon } from "../assets/icons/agents/reviews.svg";
import { ReactComponent as CommunicationIcon } from "../assets/icons/agents/communication.svg";
import { ReactComponent as PersonalizationIcon } from "../assets/icons/agents/personalization.svg";
import { ReactComponent as LoyaltyIcon } from "../assets/icons/agents/loyalty.svg";

export const agent_properties = {
  content: {
    name: "Content",
    color: "#003DD8",
    secondaryColor: "#E6EDFF",
    icon: ContentIcon,
  },
  user_experience: {
    name: "User Experience",
    color: "#0088D5",
    secondaryColor: "#E6F6FF",
    icon: UxIcon,
  },
  accessibility: {
    name: "Accessibility",
    color: "#2A6F00",
    secondaryColor: "#E8F5E6",
    icon: AccessibilityIcon,
  },
  seo: {
    name: "SEO",
    color: "#9300D8",
    secondaryColor: "#F7E6FF",
    icon: SeoIcon,
  },
  performance: {
    name: "Performance",
    color: "#14947F",
    secondaryColor: "#E6F7F5",
    icon: PerformanceIcon,
  },
  loyalty: {
    name: "Benchmark",
    color: "#D82700",
    secondaryColor: "#FFE6E6",
    icon: LoyaltyIcon,
  },
  reviews: {
    name: "Reviews",
    color: "#FF6B00",
    secondaryColor: "#FFF0E6",
    icon: ReviewsIcon,
  },
  communication: {
    name: "Communication",
    color: "#64003C",
    secondaryColor: "#FFE6F0",
    icon: CommunicationIcon,
  },
  personalization: {
    name: "Personalization",
    color: "#D800C3",
    secondaryColor: "#FEE6FC",
    icon: PersonalizationIcon,
  },
};
