import React, { useState } from "react";
import { Spinner } from "../../../../components/functionality/Spinner";
import { FiExternalLink } from "react-icons/fi";

const ImageFix = ({ data }) => {
  const truncateText = (text, maxLength = 62) => {
    if (!text || text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const renderDescription = (title, description) => {
    if (title?.toLowerCase().includes("url") || title?.toLowerCase().includes("href")) {
      return (
        <a
          href={description}
          className='text-blue-600 text-sm hover:underline break-all'
          target='_blank'
          rel='noopener noreferrer'>
          {truncateText(description)}
        </a>
      );
    }
    return <p className='text-sm text-gray-600 break-all'>{description}</p>;
  };

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    setIsLoading(true);
  }, [data.content.before, data.content.after]);

  const handleImageLoad = () => setIsLoading(false);

  return (
    <div className='space-y-4'>
      <div className='flex mb-4'>
        <div className='w-1/2 pr-2 flex flex-col'>
          <div class='flex flex-row items-center  gap-0'>
            <h3 className='font-normal mb-2'>Updated:</h3>
            {data?.link?.after?.title && (
              <a
                href={data?.link?.after?.url}
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500 hover:text-blue-600 flex items-center ml-auto'>
                {data?.link?.after?.title}
                <FiExternalLink className='ml-1' />
              </a>
            )}
          </div>
          <div className='border border-gray-300 rounded-lg overflow-hidden flex-1 flex flex-col'>
            <div className='flex-grow relative min-h-[290px]'>
              {isLoading && (
                <div className='absolute inset-0 bg-white flex items-center justify-center'>
                  <Spinner size='md' />
                </div>
              )}
              <img
                key={data.content.after}
                src={data.content.after}
                alt='Updated version'
                className='w-full h-full max-h-[350px] object-contain'
                onLoad={handleImageLoad}
                onError={handleImageLoad}
              />
            </div>
            {data.subtext?.after && (
              <div className='p-3 border-t border-gray-300 bg-gray-50'>
                <p className='font-medium'>{data.subtext.after.title}</p>
                {renderDescription(data.subtext.after.title, data.subtext.after.description)}
              </div>
            )}
          </div>
        </div>

        <div className='w-1/2 pl-2 flex flex-col'>
          <div className='flex justify-between items-center'>
            <h3 className='font-normal text-gray-400 mb-2'>Original:</h3>
            {data?.link?.before?.title && (
              <a
                href={data?.link?.before?.url}
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500 hover:text-blue-600 flex items-center ml-auto'>
                {data?.link?.before?.title}
                <FiExternalLink className='ml-1' />
              </a>
            )}
          </div>
          <div className='border border-gray-300 rounded-lg overflow-hidden flex-1 flex flex-col'>
            <div className='flex-grow relative min-h-[290px]'>
              {isLoading && (
                <div className='absolute inset-0 bg-white flex items-center justify-center'>
                  <Spinner size='md' />
                </div>
              )}
              <img
                key={data.content.before}
                src={data.content.before}
                alt='Original version'
                className='w-full h-full max-h-[350px] object-contain'
                onLoad={handleImageLoad}
                onError={handleImageLoad}
              />
            </div>
            {data.subtext?.before && (
              <div className='p-3 border-t border-gray-300 bg-gray-50'>
                <p className='font-medium'>{data.subtext.before.title}</p>
                {renderDescription(data.subtext.before.title, data.subtext.before.description)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageFix;
