import { useState } from "react";
import { FiChevronDown, FiCopy } from "react-icons/fi";
import { ReactComponent as JiraIcon } from "../../../assets/icons/third-party/jira-icon.svg";
import { useCreateUserActionMutation, trackUserAction } from "../../../app/api/userActionApiSlice";
import { ActionType } from "../../../constants/actionTypes";
import IssuesTicketButton from "../../../components/functionality/IssuesTicketButton";
import IssueStatusButton from "../../../components/functionality/IssueStatusButton";

const htmlToText = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");

  const headers = doc.querySelectorAll("h1, h2");
  headers.forEach((header) => {
    header.insertAdjacentText("afterend", "\n\n");
  });

  doc.querySelectorAll("ul > li").forEach((item) => {
    if (!item.closest("ol")) {
      item.insertAdjacentText("afterbegin", "• ");
    }
    item.insertAdjacentText("beforeend", "\n");
  });

  doc.querySelectorAll("ol").forEach((list) => {
    let index = 1;
    list.querySelectorAll(":scope > li").forEach((item) => {
      item.insertAdjacentText("afterbegin", `${index}. `);
      item.insertAdjacentText("beforeend", "\n");
      index++;
    });
  });

  doc.querySelectorAll("ol li ul").forEach((list) => {
    list.querySelectorAll("li").forEach((item) => {
      item.textContent = item.textContent.replace("• ", "");
      item.insertAdjacentText("afterbegin", "    • ");
      item.insertAdjacentText("beforeend", "\n");
    });
    list.insertAdjacentText("afterend", "\n");
  });

  const lists = doc.querySelectorAll("ul, ol");
  lists.forEach((list) => {
    list.insertAdjacentText("afterend", "\n");
  });

  return doc.body.textContent.replace(/\n{3,}/g, "\n\n").trim();
};

const TicketSection = ({ title, content, isExpanded, onToggle, issueId }) => {
  const [showCopied, setShowCopied] = useState(false);
  const [createUserAction] = useCreateUserActionMutation();

  const handleCopy = () => {
    navigator.clipboard.writeText(`${title}\n\n${htmlToText(content)}`);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 1000);

    // Track the clipboard action
    trackUserAction(createUserAction, ActionType.BUTTON_CLICK, {
      button_used: "clipboard_section",
      issue_id: issueId,
      section: title,
    });
  };

  return (
    <div>
      <div className='flex justify-between items-center'>
        <div onClick={onToggle} className='flex items-center py-4 gap-3 cursor-pointer flex-grow'>
          <FiChevronDown className={`w-5 h-5 transition-transform ${isExpanded ? "transform rotate-180" : ""}`} />
          <span className='font-medium text-lg'>{title}</span>
        </div>
        <div className='relative'>
          <button onClick={handleCopy} className='flex items-center gap-2 px-3 py-1'>
            <FiCopy className='w-5 h-5 text-[#42485D99]' />
          </button>
          {showCopied && (
            <div className='absolute -top-8 right-0 bg-gray-800/90 text-white/90 px-2 py-1 rounded text-xs'>
              Copied!
            </div>
          )}
        </div>
      </div>
      {isExpanded && (
        <div
          className='px-8 
          [&>div>h1]:text-xl [&>div>h1]:font-medium [&>div>h1]:mb-6
          [&>div>h2]:text-base [&>div>h2]:font-medium [&>div>h2]:mb-4 [&>div>h2]:mt-2
          [&>div>ol]:list-decimal [&>div>ol]:ml-6 [&>div>ol]:mb-4
          [&>div>ul]:list-disc [&>div>ul]:ml-6 [&>div>ul]:mb-4
          [&>div>p]:mb-4
          [&_a]:text-blue-600 [&_a]:underline'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      <hr className='border-gray-200 -mx-6' />
    </div>
  );
};

const FixResolution = ({ fix, subfix }) => {
  const [expandedSections, setExpandedSections] = useState(() => {
    const sections = subfix.ticket.ticket_body.split(/<h1>/).slice(1);
    return sections.reduce((acc, _, index) => ({ ...acc, [index]: true }), {});
  });
  const [createUserAction] = useCreateUserActionMutation();

  const sections = subfix.ticket.ticket_body
    .split(/<h1>/)
    .slice(1)
    .map((section) => {
      const endOfTitle = section.indexOf("</h1>");
      return {
        title: section.substring(0, endOfTitle),
        content: "<div>" + section.substring(endOfTitle + 5) + "</div>",
      };
    });

  const toggleSection = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const copyTicketToClipboard = () => {
    const textContent = htmlToText(subfix.ticket.ticket_body);
    navigator.clipboard
      .writeText(textContent)
      .then(() => {
        // Track the clipboard action
        trackUserAction(createUserAction, ActionType.BUTTON_CLICK, {
          button_used: "clipboard_ticket",
          issue_id: subfix.issue_id,
        });
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <div className='bg-white border border-gray-200 rounded-lg p-6'>
      <div className='flex justify-between items-center'>
        <h3 className='text-md text-[#2C334766]'>Or resolve it with your team</h3>

        <div className='flex items-center space-x-6'>
          <div className='flex items-center gap-3'>
            <IssueStatusButton issueId={subfix.issue_id} currentStatus={fix.status} />
          </div>

          <IssuesTicketButton issueId={subfix.issue_id} onCopyTicket={copyTicketToClipboard} />
        </div>
      </div>

      <div className='my-10'>
        <div className='prose max-w-none'>
          <div className='space-y-4'>
            <h2 className='text-xl font-semibold text-gray-900'>{subfix.ticket.ticket_title}</h2>
            <p className='text-gray-700'>{subfix.ticket.ticket_description}</p>
            <div className='space-y-2'>
              <hr className='border-gray-200 -mx-6' />
              {sections.map((section, index) => (
                <TicketSection
                  key={index}
                  title={section.title}
                  content={section.content}
                  isExpanded={expandedSections[index]}
                  onToggle={() => toggleSection(index)}
                  issueId={subfix.issue_id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixResolution;
