import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

const issuesAdapter = createEntityAdapter({
  selectId: (issue) => issue.id,
});

export const issueApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // POST create new issue
    createIssue: builder.mutation({
      query: (issueData) => ({
        url: "/api/v1/issues/",
        method: "POST",
        body: issueData,
      }),
      transformResponse: (issue) => issuesAdapter.addOne(issuesAdapter.getInitialState(), issue),
      invalidatesTags: [{ type: "Issue", id: "LIST" }],
    }),

    // GET all issues (optionally filtered by website_id)
    getIssues: builder.query({
      query: ({ skip = 0, limit = 100, website_id } = {}) => ({
        url: "/api/v1/issues/",
        params: { skip, limit, website_id },
      }),
      transformResponse: (responseData) => issuesAdapter.setAll(issuesAdapter.getInitialState(), responseData),
      providesTags: (result) => [
        { type: "Issue", id: "LIST" },
        ...(result?.ids ?? []).map((id) => ({ type: "Issue", id })),
      ],
    }),

    // GET single issue by ID
    getIssue: builder.query({
      query: (issueId) => `/api/v1/issues/${issueId}`,
      transformResponse: (issue) => issuesAdapter.upsertOne(issuesAdapter.getInitialState(), issue),
      providesTags: (result, error, issueId) => [{ type: "Issue", id: issueId }],
    }),

    // PUT update existing issue
    updateIssue: builder.mutation({
      query: ({ issueId, ...updateData }) => ({
        url: `/api/v1/issues/${issueId}`,
        method: "PUT",
        body: updateData,
      }),
      transformResponse: (issue) => issuesAdapter.upsertOne(issuesAdapter.getInitialState(), issue),
      invalidatesTags: (result, error, { issueId }) => [
        { type: "Issue", id: issueId },
        { type: "Issue", id: "LIST" },
      ],
    }),

    // DELETE remove issue
    deleteIssue: builder.mutation({
      query: (issueId) => ({
        url: `/api/v1/issues/${issueId}`,
        method: "DELETE",
      }),
      transformResponse: (issue) => issuesAdapter.removeOne(issuesAdapter.getInitialState(), issue.id),
      invalidatesTags: (result, error, issueId) => [
        { type: "Issue", id: issueId },
        { type: "Issue", id: "LIST" },
      ],
    }),

    // PATCH update issue status
    updateIssueStatus: builder.mutation({
      query: ({ issueId, status }) => ({
        url: `/api/v1/issues/${issueId}/status`,
        method: "PATCH",
        params: { status },
      }),
      transformResponse: (issue) => issuesAdapter.upsertOne(issuesAdapter.getInitialState(), issue),
      invalidatesTags: (result, error, { issueId }) => [
        { type: "Issue", id: issueId },
        { type: "Issue", id: "LIST" },
      ],
    }),

    // PATCH update issue feedback
    updateIssueFeedback: builder.mutation({
      query: ({ issueId, feedback_thumbsup, feedback_message = "None" }) => ({
        url: `/api/v1/issues/${issueId}/feedback`,
        method: "PATCH",
        body: { feedback_thumbsup, feedback_message },
      }),
      transformResponse: (issue) => issuesAdapter.upsertOne(issuesAdapter.getInitialState(), issue),
      invalidatesTags: (result, error, { issueId }) => [
        { type: "Issue", id: issueId },
        { type: "Issue", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useCreateIssueMutation,
  useGetIssuesQuery,
  useGetIssueQuery,
  useUpdateIssueMutation,
  useDeleteIssueMutation,
  useUpdateIssueStatusMutation,
  useUpdateIssueFeedbackMutation,
} = issueApiSlice;

export const selectIssueById = createSelector(
  [(state) => state.api.queries, (state, issueId) => issueId],
  (queries, issueId) => {
    const issuesQuery = Object.values(queries).find((query) => query?.data?.entities && query.data.entities[issueId]);

    return issuesQuery?.data?.entities[issueId] || null;
  }
);
