import { apiSlice } from "./apiSlice";

export const userActionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create a new user action
    createUserAction: builder.mutation({
      query: (actionData) => {
        return {
          url: "/api/v1/actions/",
          method: "POST",
          body: actionData,
        };
      },
    }),
  }),
});

export const {
  useCreateUserActionMutation,
} = userActionApiSlice;

// Helper function to track user actions
export const trackUserAction = async (trigger, actionType, metadata = null) => {
  try {
    const result = await trigger({
      action_type: actionType,
      action_metadata: metadata ? JSON.stringify(metadata) : null,
    }).unwrap();
  } catch (err) {
    // Silently fail for tracking errors
  }
}; 