import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials } from "../authSlice";

export const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.REACT_APP_NODE_ENV === "development"
      ? "http://localhost:8000"
      : //  ?  "https://staging.api.focalstudio.ai"
      process.env.REACT_APP_NODE_ENV === "staging"
      ? "https://staging.api.focalstudio.ai"
      : "https://api.focalstudio.ai",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    const refreshResult = await baseQuery("/refresh", api, extraOptions);
    if (refreshResult?.data) {
      api.dispatch(setCredentials({ accessToken: refreshResult.data.access_token }));
      result = await baseQuery(args, api, extraOptions);
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = "Your login has expired.";
      }
      return refreshResult;
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Website", "Issue", "Survey"],
  endpoints: (builder) => ({}),
});
