import React from "react";
import { useSelector } from "react-redux";
import { useGetShouldShowSurveyQuery, useMarkSurveyShownMutation } from "../app/api/userApiSlice";
import UserSurvey from "../features/users/UserSurvey";
import useAuth from "../hooks/useAuth";
import { selectCurrentWebsiteId } from "../app/selectedWebsiteSlice";
import aiLogo from "../assets/icons/ai/integrations-image.svg";

function SurveyPage() {
  const { id: userId } = useAuth();
  const selectedWebsiteId = useSelector(selectCurrentWebsiteId);
  const [markSurveyShown] = useMarkSurveyShownMutation();
  const { data: shouldShowSurvey } = useGetShouldShowSurveyQuery({ userId });

  React.useEffect(() => {
    if (shouldShowSurvey?.available_surveys?.length > 0 && selectedWebsiteId && userId) {
      const availableSurvey = shouldShowSurvey.available_surveys[0];
      try {
        markSurveyShown({
          userId,
          templateId: availableSurvey.template.id,
        }).unwrap();
      } catch (err) {
        console.error("Error in markSurveyShown:", err);
      }
    }
  }, [shouldShowSurvey, markSurveyShown, userId, selectedWebsiteId]);

  return (
    <div className='min-h-screen  py-5'>
      <div className='max-w-3xl mx-auto flex flex-col items-center justify-center'>
        <div className='flex items-center gap-4 mb-2'>
          <img src={aiLogo} alt='AI Logo' className='h-48 w-auto' />
          <div className='text-left'>
            <h2 className='text-3xl font-bold text-gray-900'>Feedback Survey</h2>
            <p className='mt-2 text-gray-600'>Help us improve your experience</p>
          </div>
        </div>

        {shouldShowSurvey?.available_surveys?.[0] ? (
          <UserSurvey
            templateId={shouldShowSurvey.available_surveys[0].template.id}
            onClose={() => window.history.back()}
          />
        ) : (
          <div className='p-8 bg-white shadow rounded-lg text-center'>
            <h3 className='text-xl font-medium text-gray-900 mb-2'>No Surveys Available</h3>
            <p className='text-gray-600'>Check back later for new surveys</p>
            <button
              onClick={() => window.history.back()}
              className='mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors'>
              Go Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SurveyPage;
