import { useState, useEffect } from "react";
import { useUpdateIssueStatusMutation } from "../../app/api/issueApiSlice";
import { IssueStatus } from "../../constants/issueStatus";
import IssueStatusIcon from "./IssueStatusIcon";
import { FiChevronDown } from "react-icons/fi";

const IssueStatusButton = ({ issueId, currentStatus = IssueStatus.IN_PROGRESS }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [updateIssueStatus] = useUpdateIssueStatusMutation();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (isOpen && !e.target.closest(".status-dropdown-container")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, [isOpen]);

  if (!issueId) return null;

  const statusOptions = [IssueStatus.IN_PROGRESS, IssueStatus.DONE, IssueStatus.TO_DO];

  const handleStatusChange = (newStatus) => {
    updateIssueStatus({
      issueId,
      status: newStatus.toLowerCase(),
    });
    setIsOpen(false);
  };

  return (
    <div className='relative status-dropdown-container'>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='w-fit h-[40px] rounded-xl flex items-center text-xs font-medium bg-white text-[#171D30] border border-[#E9E9F0] px-3 min-w-[130px]'>
        <span className='flex items-center gap-2'>
          <span className='w-5 flex justify-center'>
            {currentStatus === IssueStatus.TO_DO ? (
              <div className={`rounded-full border-2 border-gray-300 w-5 h-5`} />
            ) : (
              <IssueStatusIcon status={currentStatus.toLowerCase()} small={true} />
            )}
          </span>
          <span className='capitalize'>{currentStatus.replace(/_/g, " ")}</span>
          <FiChevronDown className='w-4 h-4 text-[#42485D] text-opacity-60' />
        </span>
      </button>

      {isOpen && (
        <div className='absolute right-0 mt-2 w-full bg-white rounded-md shadow-lg z-50 border border-gray-200'>
          <div className='py-1'>
            {statusOptions
              .filter((status) => status !== currentStatus)
              .map((status) => (
                <button
                  key={status}
                  onClick={() => handleStatusChange(status)}
                  className='block w-full text-left px-2 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                  <span className='flex items-center gap-2'>
                    <span className='w-5 flex justify-center'>
                      {status === IssueStatus.TO_DO ? (
                        <div className={`rounded-full border-2 border-gray-300 w-5 h-5`} />
                      ) : (
                        <IssueStatusIcon status={status.toLowerCase()} small={true} />
                      )}
                    </span>
                    <span className='capitalize'>{status.replace(/_/g, " ")}</span>
                  </span>
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IssueStatusButton;
