import { ReactComponent as PendingIcon } from "../../assets/icons/issues/pending.svg";
import SeverityIndicator from "./SeverityIndicator";

function IssueStatusIcon({ status, severity, small = false }) {
  const iconSize = small ? "w-2.5 h-2.5" : "w-3 h-3";
  const pendingIconSize = small ? "w-5 h-5" : "w-6 h-6";
  const containerSize = small ? "p-1" : "p-1.5";

  if (status === "done") {
    return (
      <div className={`text-white rounded-full ${containerSize}`} style={{ backgroundColor: "#40C6A7" }}>
        <svg
          className={iconSize}
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          stroke='currentColor'
          strokeWidth='3'>
          <path d='M5 13l4 4L19 7' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      </div>
    );
  }

  if (status === "in_progress") {
    return (
      <div className={`text-white rounded-full ${containerSize}`}>
        <PendingIcon className={pendingIconSize} />
      </div>
    );
  }

  if (status === "discarded") {
    return (
      <div className={`text-white rounded-full ${containerSize} bg-gray-400`}>
        <svg
          className={iconSize}
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          stroke='currentColor'
          strokeWidth='3'>
          <path d='M6 18L18 6M6 6l12 12' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      </div>
    );
  }

  return <SeverityIndicator severity={severity} small={small} />;
}

export default IssueStatusIcon;
