import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleIcon } from "../../assets/icons/third-party/GoogleIcon.tsx";
import { setCredentials } from "../../app/authSlice.js";
import { selectCurrentToken } from "../../app/authSlice.js";
import { useCreateUserActionMutation, trackUserAction } from "../../app/api/userActionApiSlice";
import { ActionType } from "../../constants/actionTypes";

const GoogleLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeState = useSelector((state) => state);
  const token = useSelector(selectCurrentToken);
  const [createUserAction] = useCreateUserActionMutation();

  //! NOTE: replace by usequery later
  const fetchUrl =
    process.env.REACT_APP_NODE_ENV === "development"
      ? "http://localhost:8000/api/v1/google"
      : //  ?  "https://staging.api.focalstudio.ai"
      process.env.REACT_APP_NODE_ENV === "staging"
      ? "https://staging.api.focalstudio.ai/api/v1/google"
      : "https://api.focalstudio.ai/api/v1/google";

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const accessToken = urlParams.get("access_token");
    if (accessToken) {
      dispatch(setCredentials({ accessToken: accessToken }));
      // Track successful login - runs async in background
      trackUserAction(createUserAction, ActionType.LOGIN, "");
      navigate("/dashboard");
    }
  }, [dispatch, navigate, storeState, token]);

  return (
    <a href={fetchUrl}>
      <button className='w-full flex items-center justify-center gap-1 rounded-xl  px-12 py-3 border shadow-sm text-sm font-medium  bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
        <GoogleIcon />
        <span className='ml-2'>Google</span>
      </button>
    </a>
  );
};

export default GoogleLogin;
