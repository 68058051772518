import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../app/api/authApiSlice";
import { useCreateUserActionMutation, trackUserAction } from "../../app/api/userActionApiSlice";
import { ActionType } from "../../constants/actionTypes";
import { setCredentials } from "../../app/authSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [login, { isLoading }] = useLoginMutation();
  const [createUserAction] = useCreateUserActionMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ username_or_email: usernameOrEmail, password }).unwrap();
      dispatch(setCredentials({ accessToken: userData.access_token }));
      
      // Track successful login - runs async in background
      trackUserAction(createUserAction, ActionType.LOGIN, "");

      navigate("/dashboard");
    } catch (err) {
      setErrMsg(err.data?.message || "Login Failed");
    }
  };

  return (
    <div className='w-full max-w-sm'>
      <form onSubmit={handleSubmit} className='mb-4'>
        <div className='mb-4'>
          <input
            type='text'
            placeholder='Username or Email'
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
            className='w-full px-3 py-2.5 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500'
            required
          />
        </div>
        <div className='mb-4'>
          <input
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='w-full px-3 py-2.5 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500'
            required
          />
        </div>
        <button
          type='submit'
          className='w-full bg-[#262836] text-white py-2 px-4 rounded-xl hover:bg-[#2f3242] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          disabled={isLoading}>
          {isLoading ? "Signing in..." : "Sign in"}
        </button>
      </form>

      {errMsg && <p className='mt-2 text-center text-sm text-red-600'>{errMsg}</p>}
    </div>
  );
};

export default Login;
