import { ReactComponent as JiraIcon } from "../../assets/icons/third-party/jira-icon.svg";
import { FiChevronDown, FiCopy } from "react-icons/fi";
import { useState, useEffect } from "react";
import { useUpdateIssueStatusMutation } from "../../app/api/issueApiSlice";
import { useCreateUserActionMutation, trackUserAction } from "../../app/api/userActionApiSlice";
import { ActionType } from "../../constants/actionTypes";

const IssuesTicketButton = ({ issueId, onCopyTicket, onAssignJira = () => {}, defaultType = "copy" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonType, setButtonType] = useState(defaultType);
  const [showCopied, setShowCopied] = useState(false);
  const [createUserAction] = useCreateUserActionMutation();

  const buttonStyle =
    buttonType === "assign" ? "bg-[#171D30] text-white" : "bg-white text-[#171D30] border border-[#E9E9F0]";

  const dividerStyle = buttonType === "assign" ? "border-[#5B5F6D]" : "border-[#E9E9F0]";
  const iconAndNumberStyle = buttonType === "assign" ? "text-white" : "text-[#42485D] text-opacity-60";
  const chevronStyle = buttonType === "assign" ? "hover:bg-[#272D40]" : "hover:bg-gray-50";

  const [updateIssueStatus] = useUpdateIssueStatusMutation();

  const handleAction = async (e) => {
    e.stopPropagation();

    try {
      await updateIssueStatus({
        issueId: issueId,
        status: "in_progress",
      }).unwrap();

      if (buttonType === "copy") {
        onCopyTicket();
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 1000);

        // Track the clipboard action
        trackUserAction(createUserAction, ActionType.BUTTON_CLICK, {
          button_used: "clipboard_ticket",
          issue_id: issueId,
        });
      }
    } catch (err) {
      console.error("Failed to update status:", err);
    }
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (isOpen && !e.target.closest(".dropdown-container")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, [isOpen]);

  return (
    <div className='relative dropdown-container'>
      <button
        onClick={handleAction}
        className={`w-fit h-[40px] rounded-xl flex items-center text-xs font-medium ${buttonStyle}`}>
        <span
          className={`px-3 h-full flex items-center ${
            buttonType === "assign" ? "hover:bg-[#272D40]" : "hover:bg-gray-50"
          } rounded-l-xl`}
          onClick={handleAction}>
          <span className='flex items-center gap-2'>
            {buttonType === "assign" ? <JiraIcon className='w-5 h-5' /> : <FiCopy className='w-4 h-4' />}
            {buttonType === "assign" ? "Assign to Jira" : "Copy to Clipboard"}
          </span>
        </span>
        <div
          className={`flex items-center justify-center px-3 h-full ${iconAndNumberStyle} ${chevronStyle} cursor-pointer rounded-r-xl border-l ${dividerStyle}`}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}>
          <FiChevronDown className='w-4 h-4' />
        </div>
      </button>

      {showCopied && (
        <div className='absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800/90 text-white/90 px-2 py-1 rounded text-xs'>
          Copied!
        </div>
      )}

      {isOpen && (
        <div className='absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 border border-gray-200'>
          <div className='py-1'>
            <button
              onClick={() => {
                setButtonType("copy");
                setIsOpen(false);
              }}
              className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
              <span className='flex items-center gap-2'>
                <FiCopy className='w-4 h-4' />
                Copy to Clipboard
              </span>
            </button>
            <button disabled className='block w-full text-left px-4 py-2 text-sm text-gray-400 cursor-not-allowed'>
              <span className='flex items-center gap-2'>
                <JiraIcon className='w-4 h-4' />
                Assign to Jira
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IssuesTicketButton;
